<template>
  <div class="w1092">
      <div class="board">
        <div class="boardrd first">
<!--          <span class="blc">스포츠북</span>-->
          <span class="ml10">{{item.msgTitle}}</span>
          <span class="newicon ml5">N</span>
        </div>
        <div class="boardrd">
          <img src="@/assets/img/hawaiilogo.png"><span class="ml10">{{dateFormat(item.sendDate)}}</span>
        </div>
        <div class="boardrd hauto">
          <p class="rdcon">{{item.msgDesc}}</p>
        </div>
      </div>
      <div class="boardbtn">
        <a class="blsbtn left" @click="onPrevMessage">{{$t('front.board.prev')}}</a>
        <a class="blksbtn center" @click="onMessageList">{{$t('front.board.list')}}</a>
        <a class="blsbtn right" @click="onNextMessage">{{$t('front.board.next')}}</a>
      </div>
    </div>
</template>

<script>

import { setMsgRead } from '@/api/board'
import { mapState } from 'vuex'

export default {
  name: 'messageRead',
  created () {
    this.item = this.$route.params
    this.setRead()
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  methods: {
    setRead () {
      this.item.status = 'R'

      const param = {
        msgIdx: this.item.msgIdx,
        recieveId: this.item.recieveId
      }
      setMsgRead(param).then(response => {

      })
    },
    onMessageList () {
      this.goBack()
    },
    onPrevMessage () {

    },
    onNextMessage () {

    }
  }
}
</script>
<style scoped>
.boardrd:last-child {padding: 20px;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
